import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

// import Button from "./components/Button";

import { userService } from './auth/auth.js';

import logo from './logo_cropped.jpg';
import './App.css';

const textObject = window.textObject;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: localStorage.getItem('login'),
    };
  }

  handleLogin(e) {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    const host = (process.env.NODE_ENV !== "development") ? '' : 'http://localhost:2717';
    userService.login(host, form.Username.value, form.Password.value).then(function() {
      window.location.reload();
    }).catch(e => {
      console.error("fail", e);
      this.setState({ showError: true });
    });
  }

  gettext(text) {
    let rtnText = text;
    let language = this.state.language;
    if (!language) {
      return rtnText;
    }
    if (!(language in textObject)) {
      console.error("language not found", language);
    } else if (!(text in textObject[language])) {
      console.error("text not found", text);
    } else {
      rtnText = textObject[language][text];
    }
    return rtnText;
  }

  getCSV() {
    /*
     * Can't use Basic auth since it has file attachment
     * Instead, store basic auth as temporary cookie for use by server
     * Then delete cookie with a 500 millisecond window for the connection to be made
     */
    let login = JSON.parse(localStorage.getItem('login'));
    document.cookie = "authdata='" + login.authdata + "'";

    const host = (process.env.NODE_ENV !== "development") ? '' : 'http://localhost:2717';
    window.open(host + '/api/skinformatix/csv', '_blank');

    window.setTimeout(function() { document.cookie = "authdata="; }, 30000);
  }

  getJSON() {
    /*
     * Can't use Basic auth since it has file attachment
     * Instead, store basic auth as temporary cookie for use by server
     * Then delete cookie with a 500 millisecond window for the connection to be made
     */
    let login = JSON.parse(localStorage.getItem('login'));
    document.cookie = "authdata='" + login.authdata + "'";

    const host = (process.env.NODE_ENV !== "development") ? '' : 'http://localhost:2717';
    window.open(host + '/api/skinformatix/reports', '_blank');

    window.setTimeout(function() { document.cookie = "authdata="; }, 30000);
  }

  getLogin() {
    if (!this.state.login) {
      // popup login modal
      return (
        <Modal show={!this.state.login}>
          <Modal.Header closeButton>
            <Modal.Title>Login</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              onSubmit={e => this.handleLogin(e)}
            >
              <Form.Group controlId="Username">
                <Form.Label>Username</Form.Label>
                <Form.Control required type="username" placeholder="Enter username" />
              </Form.Group>

              <Form.Group controlId="Password">
                <Form.Label>Password</Form.Label>
                <Form.Control required type="password" placeholder="Password" />
              </Form.Group>
              <Button variant="primary" type="submit">
                Login
              </Button>
              {" "}
              { this.state.showError &&
                <label className="control-label text-danger">{this.gettext("Bad Credentials")}</label>
              }
            </Form>
          </Modal.Body>
        </Modal>
    );
    } else {
      return(
        <div className="login">
          <button
            className="btn btn-link"
            onClick={
              function() {
                userService.logout();
                window.location.reload();
              }
            }
          >
            Logout
          </button>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="App">
        <header className="App-header col-md-4 offset-md-4">
          <img src={logo} className="App-logo" alt="logo" />
          {this.getLogin()}
        </header>
        <div>
          <Button variant="primary" type="primary" onClick={this.getCSV} style={buttonStyle}>
            Clients CSV
          </Button>
          {" "}
        </div>
        <div>
          <Button variant="primary" type="primary" onClick={this.getJSON} style={buttonStyle}>
            Client Reports JSON
          </Button>
          {" "}
        </div>
      </div>
    );
  }
}

const buttonStyle = {
  margin: "10px"
};

export default App;
