
export const userService = {
    login,
    logout,
    request
};

function authHeader() {
    // return authorization header with basic auth credentials
    let login = JSON.parse(localStorage.getItem('login'));

    if (login && login.authdata) {
        return { 'Authorization': 'Basic ' + login.authdata };
    } else {
        return {};
    }
}

function login(url, username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: username, password: password })
    };

    return fetch(`${url}/api/login`, requestOptions)
        .then(handleResponse)
        .then(login => {
            // login successful if there's a login in the response
            if (login) {
                // store login details and basic auth credentials in local storage
                // to keep user logged in between page refreshes
                login.authdata = window.btoa(username + ':' + password);
                login.user = username;
                localStorage.setItem('login', JSON.stringify(login));
            }

            return login;
        }).catch(e => {
          throw e;
        });
}

function logout() {
    // remove login from local storage to log user out
    localStorage.removeItem('login');
}

function request(url, options) {
    const requestOptions = {
      ...options,
        headers: authHeader()
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 400) {
              // auto logout if 400 response returned from api
              if (localStorage.getItem('login')) {
                logout();
                window.location.reload(true);
              }
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
